:root {
    --main-color: #5c6bc0;
    --main-color-lighter: #7986cb;
    --main-color-darker: #283593;
    --secondary-color: #42a5f5;
}

#navbar {
    background: var(--main-color);
}
#navbar a:hover {
    opacity: 1;
}
#navbar a {
    opacity: 0.8;
    color: white !important;
}
@media only screen and (min-width: 768px) {
    /* navbar buttons text content below are uneven and looks ugly */
    #navbar a.item {
        transform: translateX(-12px);
    }
}

.email-and-name-form {
    min-width: 320px;
    text-align: center;
    align-self: center;
    justify-self: center;
    padding: 1em 0;
}
.email-and-name-form hr {
    margin: 2em 0;
}

.intro {
    min-height: 10em;
    font-size: 1.25em;
    line-height: 1.75em;
    display: flex;
    /* overflow-y: auto; */
    margin-bottom: 5em;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.intro > div {
    width: 200px;
    min-width: 200px;
    height: 200px;
    background-color: white;
    margin: 10px;
    text-align: center;
    box-shadow: 0 5px 15px -2px rgba(0, 0, 0, 0.1);
}
.intro .tiles {
    width: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
}
.intro .tiles > * {
    border-top: lightgrey solid 7px;
    width: 20%;
    margin-right: 1px;
}
.intro .tiles > *:last-child {
    margin-right: 0;
}
.intro img {
    width: 60%;
}
.intro .content {
    display: flex;
    align-items: center;
    padding: 1em 0.1em;
    height: 40%;
    margin-bottom: 1em;
}
.intro .content .digit {
    font-size: 3.2em;
    font-weight: 100 !important;
    margin: 5px 10px 0 5px;
    font-family: Helvetica, sans-serif;
    align-self: flex-start;
}
.intro .content .text {
    font-size: 0.8em;
    line-height: 1.2em;
    text-transform: uppercase;
    text-align: left;
}
/* @keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} */
/* .intro > div {
    animation: fadein ease-in 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}
.intro > div:nth-child(1) {
    animation-delay: 0s;
}
.intro > div:nth-child(2) {
    animation-delay: 0.2s;
}
.intro > div:nth-child(3) {
    animation-delay: 0.4s;
}
.intro > div:nth-child(4) {
    animation-delay: 0.6s;
} */

.new-markets-info {
    min-height: 100px;
    max-width: 800px;
    margin: 0 auto;
    /* display: flex; */
}
.new-markets-info > a.ui.basic.blue.button.question {
    box-shadow: none !important;
    text-align: center;
    width: 100%;
    word-spacing: 6px;
    font-size: 1.5em;
}
.new-markets-info #join-as-store {
    padding-top: 50px;
}
.new-markets-info p {
    font-size: 1.2em;
    line-height: 1.7em;
}
.new-markets-info .actions {
    text-align: center;
    margin-top: 30px;
}
.new-markets-info .actions > * {
    margin: 1em;
}

.market-view-holder {
    max-width: 800px;
    margin: 0 auto;
}
.market-view-holder hr {
    opacity: 0.4;
    margin: 1.5em 0;
}
.market-view .market-name {
    opacity: 0.9;
}
.market-view .address {
    color: #777;
}
.market-view .market-description {
    margin: 2em 0;
    white-space: pre-wrap;
}
.market-view .unregistered .ui.button {
    margin: 5px;
}
.market-view .claim-business {
    display: inline-block;
    margin: 1.2em 0;
    font-weight: 500;
}

.order-form {
    /* max-width: 500px !important; */
}
.order-form textarea {
    height: 200px;
}

.online-orders-not-supported {
    text-align: center;
    opacity: 0.8;
    font-size: 1.25em;
    margin: 5em 0;
}

.markets-list {
    margin: 1em;
}
.markets-list .ui.card .header {
    min-height: 2.5em;
}
.markets-list .ui.card .meta {
    min-height: 2em;
}

/* .markets-list .ui.card > .extra a:not(.ui) {
    color: #1e70bf;
}
.markets-list .ui.card > .extra a:not(.ui):hover {
    opacity: 0.8;
} */

.markets-list a.ui.basic.button.action-button:hover {
    opacity: 1;
}
.markets-list a.ui.basic.button.action-button {
    box-shadow: 0 0 0 1px inset !important;
    color: #16b31d !important;
    /* box-shadow: 0 0 0 1px var(--secondary-color) inset !important;
    color: var(--secondary-color) !important; */
    /* box-shadow: 0 0 0 1px #00c853 inset !important;
    color: #00c853 !important; */
    opacity: 0.9;
}

.market-not-found {
    opacity: 0.75;
    font-size: 2em;
}

.market-not-found,
.login-container {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .login-container-inner {
    min-width: 330px;
}
.login-container-inner .firebaseui-list-item {
    margin: 2em !important;
} */
#firebaseui-auth-container > .firebaseui-container {
    padding: 1em 0;
}
.change-phone-form .input-field {
    width: 220px;
}
.change-phone-form .error {
    color: red;
}

.orders-list .completed {
    opacity: 0.5;
}

.orders-list .order-header .market-logo-name {
    min-width: 100px;
}
.orders-list .order-header {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
}

.order-history-message {
    margin: 0.7em 0;
}

.order-message {
    margin: 0.7em 0;
}
.order-message textarea {
    width: 100%;
    padding: 5px;
    min-height: 100px;
}

.picture-container {
    display: inline-block;
}
.framed {
    border: 3px solid #b4bde4;
    border-radius: 5px;
    padding: 3px;
}
.picture-container img,
.site-logo-container img {
    /* max-height: 7em; */
    /* max-width: 7em; */
    max-height: 150px;
    max-width: 200px;
}
.gallery-image-container > img,
.gallery-add-image-container > * {
    height: 150px;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-add-image-container i {
    /* font-size: 5em; */
    color: #b4bde4;
}

.gallery-image-container {
    position: relative;
    display: inline-block;
    margin: 0.5em;
}
.gallery-image-container:hover > .delete-icon {
    display: block;
}
.gallery-image-container .ui.circular.button {
    /* display: none; */
    position: absolute;
    top: -18px;
    right: -18px;
    padding: 5px;
    width: 35px;
    height: 35px;
    background-color: #ccc;
    border-radius: 50%;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.gallery > * {
    margin: 0 2em 2em 0;
}
