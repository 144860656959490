body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9f9f9 !important;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
#root {
    flex: 1 0 auto;
    flex-direction: column;
    display: flex;
}
#footer {
    background-color: white;
    width: 100%;
    margin-top: 5em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
